<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | METALANDIS` : `METALANDIS` }}</template>
  </metainfo>
  <header-comp></header-comp>
  <router-view/>
  <footer-comp></footer-comp>
</template>

<script>
import HeaderComp from "./components/HeaderComp.vue";
import FooterComp from "./components/FooterComp.vue";

export default {
  name : "App",
  components : {
    HeaderComp,
    FooterComp
  },
  mounted() {
    //load curent lang
    if(localStorage.safeLang) {
      this.$i18n.locale = localStorage.safeLang;
    }else {
      this.$i18n.locale = "fr";
    }    
  },
  watch: {
    '$i18n.locale' : function(newName) {
      localStorage.safeLang = newName;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  padding:0;
  margin: 0;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
}

p {
  color:$white;
  font-weight:400;
  font-family: 'Montserrat', sans-serif;
  margin:0 0 20px 0;
  font-size:15px;
  line-height: 1.3em;
}


.box {
  width:$box;
  max-width: $maxWidth;
  margin:0 auto;
}

h3.card-title-light span {
    font-weight: 200;
}

h2.feature-subtitle span {
    font-weight:700;
    background: -webkit-linear-gradient(0deg, $mainColor, $secondColor);
    background-clip: text;
    -webkit-text-fill-color: transparent
  }

h1.home-title span {
  font-size: 50px;
}

.row {
  display: flex;
  width: 100%;
}

.col-25 {
  width:23%;
  margin:0 1%;
}

.col-50 {
  width:48%;
  margin:0 1%;
}

.col-100 {
  width:98%;
  margin:0 1%;
}

.btn {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-size:20px;
    color:#fff;
    position: relative;
    border-radius: 40px;
    padding:8px 30px;
    transition: 0.5s;
    outline: none;
    appearance: none;
    text-decoration: none;
    border:1px solid $secondColor;
    background:$secondColor;
    cursor: pointer;
    text-align: center;
    transition:0.5s;
}

// .btn:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border-radius: 40px; 
//     border:1px solid $mainColor;
//     border: 1px solid transparent;
//     background: linear-gradient(-90deg,$mainColor,$secondColor) border-box;
//     -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
//     -webkit-mask-composite: destination-out;    
//     mask-composite: exclude;
// }

.btn:hover {
    // background-clip:unset;
    // background: -webkit-linear-gradient(180deg, $mainColor, $secondColor);
    background: $mainColor;
    border: 1px solid $mainColor;
}

.title-container {
  display: flex;
  justify-content: center;
}

h1.default-title {
    font-weight:200;
    font-size:60px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom:80px;
    margin:0;
    background: -webkit-linear-gradient(0deg, $mainColor, $secondColor);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text .prices-value p span {
    font-weight:200;
    font-size:18px;
}

  :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 10px;
    --popper-theme-padding: 10px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }

  .popper {
    margin-left: 10px !important;
  }

  .menu-header-resp-content  .popper {
    margin:0 !important;
  }

  
  @media screen and (max-width:1600px) {
    h1.home-title span {
      font-size:40px;
    }
  }

  @media screen and (max-width:1200px) {
    h1.home-title span {
      font-size:30px;
    }
  }

  @media screen and (max-width:1024px) {

    h1.home-title span {
      font-size:25px;
    }
  }


  @media screen and (max-width:768px) {
    h1.default-title {
      font-size:35px;
      padding-bottom:40px;
    }

    h1.home-title span {
      font-size:20px;
    }

    .row {
      flex-direction: column;
    }

    .col-25, .col-50, .col-100 {
      width:100%;
    }

    .btn {
      font-size:18px;
    }

    .text .prices-value p span {
      font-size:15px;
    }

    
  }
</style>
