<template>
    <footer>
        <div class="box">
            <div class="footer-content row">
                <div class="col-menu">
                    <nav>
                        <router-link :to="{name : 'Home'}" class="menu-item">{{ $t("menu.home")}}</router-link>
                        <router-link :to="{name : 'Metalandis'}" class="menu-item">{{ $t("menu.metalandis")}}</router-link>
                        <router-link :to="{name : 'Features'}" class="menu-item">{{ $t("menu.features")}}</router-link>
                        <router-link :to="{name : 'Prices'}" class="menu-item">{{ $t("menu.prices")}}</router-link>
                        <router-link :to="{name : 'Concept'}" class="menu-item">{{ $t("menu.concept")}}</router-link>
                        <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                    </nav>
                    <div class="footer-line-container">
                        <div class="footer-circle"></div>
                        <div class="footer-line"></div>
                    </div>
                </div>
                <div class="col-logo">
                    <img src="./../assets/meta_logo_baseline.png" alt="logo" />
                </div>
                <div class="col-rs">
                    <div class="rs-container">
                        <a href="https://www.linkedin.com/company/synthes3d/" target="_blank" class="rs-icon linkedin"></a>
                        <a href="https://twitter.com/SYNTHES3D" target="_blank" class="rs-icon twitter"></a>
                        <a href="https://www.youtube.com/user/synthes3d" target="_blank" class="rs-icon youtube"></a>
                        <a href="https://www.instagram.com/synthes3d/" target="_blank" class="rs-icon instagram"></a>
                    </div>
                    <div class="footer-line-container">
                        <div class="footer-circle"></div>
                        <div class="footer-line"></div>
                    </div>
                </div>
            </div>
            <div class="footer-copyright">
                <p>
                    <span>© {{ this.currentYear}} metalandis</span> <span class="hide-resp">|</span> <span>{{$t("footer")}}</span> <span class="hide-resp">|</span> <router-link :to="{ name : 'LegalNotices'}">{{ $t("menu.legal")}}</router-link></p>
            </div>
        </div>    
    </footer>
</template>

<script>
export default {
    name : "FooterComp",
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        background-image: url("./../assets/bg_footer.png");
        background-position: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .box {
        padding-top:80px;
    }

    .footer-copyright {
        padding:20px 0;
        border-top:1px solid $white;
    }
 
    p {
        color:$white;
        font-size: 15px;
        text-align: center;
        margin:0;
        font-weight: 200;
        text-transform: uppercase;
    }

    .col-logo {
        width:40%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .col-logo img {
        max-width: 80%;
        margin-bottom:60px;
    }
    
    .col-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width:25%;
        margin-left:5%;
    }

    .col-menu nav {
        display: flex;
        flex-direction: column;
    }

    .menu-item {
        color:$white;
        font-weight: 400;
        text-transform: uppercase;
        font-size:15px;
        margin-bottom:20px;
        text-decoration: none;
        cursor: pointer;
    }

    .menu-item:hover, .menu-item.router-link-exact-active {
        -webkit-text-stroke: 1px $mainColor;
        color:$mainColor;
    }

    .footer-line-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-left:20px;
        margin-top:40px;
    }

    .footer-line {
        height:30px;
        width:1px;
        background: $white;
    }

    .footer-circle {
        background: $white;
        width:7px;
        height:7px;
        border-radius:50%;
    }

    .col-rs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width:25%;
        margin-right:5%;
    }

    .col-rs .footer-line-container{
        margin-left:0;
        margin-right:20px;
    }

    .rs-container {
        display: flex;
        flex-direction: column;
        margin-right:12px;
    }

    .rs-icon {
        cursor: pointer;
        width:30px;
        height:30px;
        transition: 0.5s;
        margin-bottom:10px;
    }

    .linkedin {
        background-image:url("./../assets/icons/linkedin.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .linkedin:hover {
        background-image:url("./../assets/icons/linkedin_o.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .instagram {
        background-image:url("./../assets/icons/instagram.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .instagram:hover {
        background-image:url("./../assets/icons/instagram_o.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twitter {
        background-image:url("./../assets/icons/twitter.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twitter:hover {
        background-image:url("./../assets/icons/twitter_o.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .youtube {
        background-image:url("./../assets/icons/youtube.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .youtube:hover {
        background-image:url("./../assets/icons/youtube_o.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    a {
        color:$white;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    @media screen and (max-width:768px) {
        .col-menu {
            width:100%;
            order:1;
            margin:0;
        }

        .col-menu nav {
            width: 100%;
            align-items: center;
            margin:0;
        }

        .col-rs {
            width:100%;
            order:2;
            margin:40px 0;
        }

        .col-logo {
            width:100%;
            order:3
        }

        .footer-line-container {
            display: none;
        }

        p {
            font-size: 13px;
        }

        .box{
            padding-top:40px;
        }

        .rs-container {
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin:0;
        }

        .rs-icon {
            margin: 0 10px 20px 10px;
        }

        .hide-resp {
            display: none;
        }

        p {
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.8em;
        }
    }

</style>