import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "./i18n";
import VTooltip from 'v-tooltip';
import { createMetaManager } from 'vue-meta';

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VTooltip)
    .use(createMetaManager())
    .mount('#app')
