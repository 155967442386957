import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/metalandis',
    name: 'Metalandis',
    component: () => import( '../views/MetalandisView.vue')
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import( '../views/FeaturesView.vue')
  },
  {
    path: '/concept',
    name: 'Concept',
    component: () => import( '../views/ConceptView.vue')
  },
  {
    path: '/prices',
    name: 'Prices',
    component: () => import( '../views/PricesView.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/ContactView.vue')
  },
  {
    path: '/legal-notices',
    name: 'LegalNotices',
    component: () => import( '../views/LegalNoticesView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

export default router
