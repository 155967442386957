<template>
    <header :class="type">
        <!-- <div class="header-lang-container">
            <div class="header-lang-switcher">
                <div :class="['lang-item', {current : $i18n.locale == 'fr'}]" @click="$i18n.locale = 'fr'">FR</div>
                <div class="menu-sep">.</div>
                <div :class="['lang-item', {current : $i18n.locale == 'en'}]" @click="$i18n.locale = 'en'">EN</div>
            </div>
        </div> -->
        <div class="header-main">
            <router-link :to="{ name : 'Home'}" class="logo-container">
                <img src="./../assets/meta_logo_baseline.png" alt="logo-metalandis" />
            </router-link>

            <nav>
                <router-link :to="{name : 'Home'}" class="menu-item">{{ $t("menu.home")}}</router-link>
                <div class="menu-sep">.</div>
                <router-link :to="{name : 'Metalandis'}" class="menu-item">{{ $t("menu.metalandis")}}</router-link>
                <div class="menu-sep">.</div>
                <router-link :to="{name : 'Features'}" class="menu-item">{{ $t("menu.features")}}</router-link>
                <div class="menu-sep">.</div>
                <router-link :to="{name : 'Prices'}" class="menu-item">{{ $t("menu.prices")}}</router-link>
                <div class="menu-sep">.</div>
                <router-link :to="{name : 'Concept'}" class="menu-item">{{ $t("menu.concept")}}</router-link>
                <div class="menu-sep">.</div>
                <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                <Popper content="Coming soon" :hover="true">
                    <div class="menu-item-presale"  >{{ $t("menu.presale")}}</div>
                </Popper>
            </nav>

            <div class="menu-container-resp">
               <!-- <div class="header-lang-switcher">
                    <div :class="['lang-item', {current : $i18n.locale == 'fr'}]" @click="$i18n.locale = 'fr'">FR</div>
                    <div class="menu-sep">.</div>
                    <div :class="['lang-item', {current : $i18n.locale == 'en'}]" @click="$i18n.locale = 'en'">EN</div>
                </div>  -->
                <!-- MENU RESP -->
                <div class="menu-header-resp">
                    <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                        <router-link :to="{name : 'Home'}" class="menu-item">{{ $t("menu.home")}}</router-link>
                        <router-link :to="{name : 'Metalandis'}" class="menu-item">{{ $t("menu.metalandis")}}</router-link>
                        <router-link :to="{name : 'Features'}" class="menu-item">{{ $t("menu.features")}}</router-link>
                        <router-link :to="{name : 'Prices'}" class="menu-item">{{ $t("menu.prices")}}</router-link>
                        <router-link :to="{name : 'Concept'}" class="menu-item">{{ $t("menu.concept")}}</router-link>
                        <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                        <Popper content="Coming soon">
                            <div class="menu-item-presale"  >{{ $t("menu.presale")}}</div>
                        </Popper>
                    </nav>
                </div>  
            </div>
        </div>            
    </header>
</template>

<script>
import Popper from "vue3-popper";

export default {
    name : "HeaderComp",
    components : {
        Popper
    },
    data() {
        return {
            type : null,
            currentScroll : 0,
            showPopper : false
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.getType();
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
     methods: {
         handleScroll() {
             this.getType();
        },
        getType() {
            const currentScroll = window.pageYOffset;
            if(window.pageYOffset < 100) {
                this.type = null;
                return
            }
            
            if (currentScroll > this.currentScroll ) {
                this.type = "hide";
            }
            else {
                this.type = "scroll";
            }
            this.currentScroll = currentScroll;
        },
    },
    watch  : {
        '$route.name' : function() {
            this.getType();
            this.$store.commit("closeMenu");
        },

    }
}
</script>

<style lang="scss" scoped>
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position:fixed;
        top:0;
        left:0;
        right:0;
        z-index: 99;
        transition: 0.5s;
    }

    header.hide {
        top:-200px;
    }

    .header-main {
        width:90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 0;
    }

    .scroll .header-lang-container {
        display: none;
    }

    header.scroll {
        background:rgba(#090311, 0.9);
    }

    header.scroll .logo-container img {
        height:70px;
    }

    header.scroll .header-main {
        padding:15px 0;
    }

    .header-lang-container {      
        background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 0.05));
        width:100%;
        display: flex;
        justify-content: flex-end;
        transition: 0.5s;
    }

    .header-lang-switcher {
        display: flex;
        justify-content: flex-end;
        width:90%;
        margin:0 5%;
        padding:5px 0;
    }

    nav {
        display: flex;
        align-items: center;
    }

    .menu-item, .lang-item {
        color:$white;
        font-weight: 400;
        text-transform: uppercase;
        font-size:18px;
        margin:0 10px;
        text-decoration: none;
        //transition:0.5s;
        cursor: pointer;
    }

    .menu-item:hover, .lang-item:hover, .menu-item.router-link-exact-active, .lang-item.current {
        -webkit-text-stroke: 1px $mainColor;
        color:$mainColor;
    }

    .menu-item-presale {
        color:$white;
        font-weight: 500;
        text-transform: uppercase;
        font-size:18px;
        margin-left:20px;
        text-decoration: none;
        background: -webkit-linear-gradient(180deg, $mainColor, $secondColor);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        border-radius: 40px;
        padding:5px 20px;
        transition: 0.5s;
        cursor: pointer;
    }

    .menu-item-presale:hover {
        background-clip:unset;
        -webkit-text-fill-color: #fff;
    }

    .menu-item-presale:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 40px; 
        border: 1px solid transparent;
        background: linear-gradient(-90deg,$mainColor,$secondColor) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }


    .lang-item {
        font-size:15px;
    }

    .menu-sep {
        color:$white;
    }

    .menu-container-resp {
        display: none;
    }

    /* MENU RESP */

    .burger-nav {
        width: 20px;
        height: 20px;
        position: relative;
        margin: 10px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $mainColor;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 8px;
    }

    .burger-nav span:nth-child(4) {
        top: 16px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 70px;
        left:0;
        right: 0;
        background: #000;
        border-bottom: 1px solid $mainColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top:1px solid $mainColor;
        margin:0;
        z-index:99;
    }

    .menu-header-resp-content .menu-item {
        width:100%;
        border: none;
        padding:10px 0;
        text-decoration: none;
        color:$white;
        font-size:13px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:auto;
        
    }

    .menu-header-resp-content .menu-item-presale {
        margin:10px 0;
    }


    @media screen and (max-width:1500px) {
        header .logo-container img {
            height:60px;
        }
    }

    @media screen and (max-width:1400px) {
        header .logo-container img, header.scroll .logo-container img {
            height:50px;
        }

        .menu-item, .menu-item-presale {
            font-size:15px;
        }
    }

    @media screen and (max-width:1100px) {
        nav {
            display: none;
        }

        .menu-container-resp {
            display: flex;
        }
    }

</style>